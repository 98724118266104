@import '../../App.scss';

video {
	position: absolute;
	overflow: hidden;
	width: 100%;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -1;
	-ms-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-15%);
}

header {
	position: relative;
	width: 100%;
	overflow: hidden;
	min-height: 500px;
}

.headerText {
	width: 100%;
	margin: 8em auto;
	display: inline-flex;
}

.headerText-left {
	text-align: right;
}

.headerText-left h1 {
	color: #fff;
	font-size: 3em;
}

.headerText-left p {
	color: #fff;
	text-align: right;
	font-size: 20px;
	width: 70%;
	display: inline-flex;
}

.headerText-right {
	background-color: $green;
	width: 220px;
	height: 220px;
	padding: 10px;
	text-align: center;
}

.headerText-right h3 {
	color: $lightgreen;
}


.headerText-right hr {
	max-width: 30%;
	color: $lightgreen;
}

.headerText-right p {
	color: #fff;
}

.aqiMsg {
	color: $secondary-color;
	margin: 20px auto;
}

.aqiMsg h2 {
	font-size: 24px;
	padding: 20px 0;
}

.aqiMsg hr {
	margin-top: -20px;
	width: 50%;
	background-color: $tertiary-color;
}

@media only screen and (max-width: 1200px) {
	.headerText-left {
		text-align: center !important;
	}

	.headerText .col-6 {
		width: 100%;
		max-width: 100%;
	}

	.headerText {
		display: block !important;
	}

	.headerText-right {
		margin: 0 auto;
		margin-bottom: 40px !important;
	}

	header video {
		transform: translateX(-50%) translateY(0);
	}

	.aqiMsg {
		text-align: center;
	}
	
	.aqiMsg hr {
		width: 30%;
	}

	.headerText-left p {
		text-align: center;
	}
}
