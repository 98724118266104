@import '../../App.scss';

.contactForm {
    box-shadow: black;
    border: solid 1px darkgray;
    padding: 5%;
    width: 50%;
    top: 20%;
    left: 30%;
    right: 30%;
    bottom: 20%;
    margin: 80px auto;
}

.mt-1 {
    margin-bottom: 10%;
}

.contact_btn {
    width: 100%;
    background-color: $secondary-color;
}

@media only screen and (max-width: 920px) {
    .contactForm {
        width: 80%;
    }
}
