@import '../../App.scss';

p {
	text-align: center;
}

.abtus {
	position: relative;
	margin: 40px auto;
}

.abtus img {
	width: 400px;
}

.abtus p {
	position: absolute;
	z-index: 1;
	top: 30%;
	color: #fff;
	padding: 20px;
}
