@import '../../App.scss';

#filters-container {
	background-color: #f7f7f7;
	margin: 0 auto;
}

#graphArea {
	background-color: #fff;
}

.filters-box {
	background-color: #fff;
	padding: 15px;
}

.filters-box form {
	background-color: $accent-color;
	padding: 150px 20px;
}

.filters-box input, .filters-box select {
	margin-bottom: 10px;
	border-radius: 0px;
}

.filters-box form button {
	background-color: $secondary-color;
	width: 100%;
	border-radius: 0;
	margin-top: 20px;
}

#filters-container .col-1, #filters-container .col-2, #filters-container .col-3, #filters-container .col-8 {
	padding: 0;
}

.vertical-center {
	display: flex;
	align-items: center;
}

#filters-container {
	padding: 40px 0;
}

@media only screen and (max-width: 940px) {
	#filters-container .col-3 {
		max-width: 100%;
		width: 100%;
		margin: 0 auto;
	}

	#filters-container .col-8 {
		width: 100%;
		max-width: 100%;
		flex: none;
	}

	.filters-box form {
		padding: 40px 40px;
		min-width: 200px;
	}

	#aqiGraph {
		padding: 10px;
	}

	.filters-box select {
		min-width: 200px;
	}
}