$primary-color: #f7f7f7;
$secondary-color: #224651;
$accent-color: #E6F4E7;
$tertiary-color: #b2d3b4;
$dark-color: #414042;

$green: #93B79B;
$lightgreen: #627b66;

.container-fluid, .col {
	padding: 0;
}

.row {
	margin: 0;
}
