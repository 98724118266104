@import '../../App.scss';

.heading1 {
    color: $secondary-color;
    font-size: 1.5rem;
    margin-top: 4%;
    font-family: Arial, Helvetica, sans-serif;
}

.para {
    margin: 0;
}
