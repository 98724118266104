@import '../../App.scss';

.navbarTop {
	background-color: $secondary-color;
}
.navbar {
	background-color: $secondary-color !important;
	text-align: right;
}

.navbar-dark .navbar-nav .nav-link {
	color: #fff;
}

.logo img {
	height: 60px;
	margin: 5px 0;
}

.navbar a {
	color: #fff;
	text-decoration: none;
	display: block;
	padding: 15px;
	font-weight: bold;
}

#responsive-navbar-nav {
	justify-content: flex-end;
}

@media only screen and (max-width: 920px) {
	.navbar a {
		padding: 15px 0;
		border-bottom: 2px solid;
	}

	.navbarTop .col-4 {
		width: 100%;
		max-width: 100%;
	}

	.navbar {
		justify-content: flex-end;
    	margin: 15px 0;
	}
}
