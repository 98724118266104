@import '../../App.scss';

footer {
	background-color: $secondary-color;
	padding: 5px 0;
	text-align: center;
}

footer p {
	color: #fff;
	text-align: center;
	padding: 4px 0 0 0;
	margin: 0;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 0.8em;
}

footer a {
	color: #fff;
}

footer a:hover {
	text-decoration: none;
	color: $accent-color;
}
